<template>
  <div class="card questions">
    <div class="card-header">
      <h3 class="card-title">Add monthly profit</h3>
      <div class="float-right">
        <router-link :to="{'name':'monthly-profit-list'}">
          <b-button size="sm" variant="outline-primary">{{ $t('btn.back') }}</b-button>
        </router-link>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-6">
          <b-card>

            <b-card-body>
              <label>Month (YYYY-MM)</label>
              <p>
                <input v-model="date" class="form-control" type="month">
              </p>

              <label>Profit</label>
              <p>
                <input v-model="profit" class="form-control" type="number" step="0.01">
              </p>

              <div class="buttons float-right">
                <b-button type="button" @click="changeData()" variant="success" class="mr-1">Save</b-button>
              </div>
            </b-card-body>
          </b-card>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import alertify from 'alertifyjs';
import axios from "axios";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "vue-select/dist/vue-select.css";

export default {
  props: ['apiKey'],
  components: {
  },
  data() {
    return {
      date: null,
      profit: null,
    };
  },
  computed: {
  },
  created() {
  },
  methods: {
    changeData() {
      if (this.date === null || this.profit === null) {
        alertify.error('Fill all fields');
        return;
      }

      const url = '/monthly-profit-configuration/create';
      let data = {
        "date" : this.date + "-01",
        "profit": parseFloat(this.profit)
      }

      console.log(data);
      axios.post(url, data)
          .then(() => {
            alertify.success('Monthly profit was added');
            this.$router.push({name: 'monthly-profit-list'});
          })
          .catch(() => {
            alertify.error('Monthly profit for this date already exists');
          });
    },
  },
  watch: {
    $route() {
    }
  }
}
</script>

